
<template>
  <div class="flex items-center">
    <div
      :style="barcodeButtonStyles(barcodeType)"
      :class="isPointer ? 'cursor-pointer' : ''"
      class="barcode-icon__icon flex items-center justify-center rounded"
    >
      <component :is="getIconByKey(barcodeType.icon)" />
    </div>
    <div v-if="isShowLabel && barcodeType.name" :style="textColorStyles" class="px-3 truncate leading-normal">
      {{ barcodeType.name }}
    </div>
  </div>
</template>
<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeTypesMixin from './mixins/BarcodeTypesMixin.vue'

@Options({
  components: {
    name: 'BarcodeIcon',
  },
})
export default class BarcodeIcon extends mixins(BarcodeTypesMixin) {
  @Prop({ type: Object }) readonly barcodeType!: IBarcodeDefinitionType
  @Prop({ type: Boolean }) readonly isSelected?: boolean = false
  @Prop({ type: Boolean }) readonly isShowLabel?: boolean = false
  @Prop({ type: Boolean }) readonly isPointer?: boolean = true

  get textColorStyles() {
    return {
      color: this.isSelected ? '#fff' : '#000',
    }
  }
}
</script>
<style lang="scss" scoped>
.barcode-icon {
  &__icon {
    width: 35px;
    height: 35px;
    svg {
      width: 20px;
      height: 16px;
    }
  }
}
</style>
