<template>
  <SvgBase iconName="Package" width="40" height="38" fill="none">
    <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0V37.38H39.34V0H0ZM16.44 4H22.9V11.81H16.44V4ZM35.34 33.38H4V4H12.44V15.81H26.9V4H35.34V33.38Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPackage extends Vue {}
</script>
