<template>
  <SvgBase iconName="Person" width="40" height="41" fill="none">
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7719 19.7C25.2019 19.7 29.6219 15.28 29.6219 9.85C29.6219 4.42 25.2019 0 19.7719 0C14.3419 0 9.92188 4.42 9.92188 9.85C9.92188 15.28 14.3419 19.7 19.7719 19.7ZM19.7719 4C22.9919 4 25.6219 6.62 25.6219 9.85C25.6219 13.08 23.0019 15.7 19.7719 15.7C16.5419 15.7 13.9219 13.08 13.9219 9.85C13.9219 6.62 16.5419 4 19.7719 4Z"
        :fill="color"
      />
      <path
        d="M30.66 21.4609H8.88C3.99 21.4609 0 25.4409 0 30.3409V40.1509H39.53V30.3409C39.53 25.4509 35.55 21.4609 30.65 21.4609H30.66ZM35.54 36.1509H4V30.3409C4 27.6509 6.19 25.4609 8.88 25.4609H30.66C33.35 25.4609 35.54 27.6509 35.54 30.3409V36.1509Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPerson extends Vue {}
</script>
