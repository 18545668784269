<template>
  <SvgBase iconName="Airplane" width="50" height="34" fill="none">
    <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.41 1.76H41.46L36.94 7.9L33.1 7.19L39.78 0H26.57L19.19 4.62L13.75 3.61C9.21002 2.77 4.50002 4.62 1.75002 8.33L1.32002 8.91C-0.189983 10.92 -0.419983 13.56 0.710017 15.8C1.83002 18.04 4.09002 19.43 6.60002 19.43H15.16L28.97 33.34H41.76L31.71 19.43H44.66L49.41 1.76ZM30.64 29.33L21.32 19.95H27.16L33.94 29.33H30.64ZM27.72 4H30.61L28.52 6.34L24.92 5.68L27.72 4ZM41.61 15.43H6.59002C5.59002 15.43 4.73002 14.9 4.28002 14C3.83002 13.11 3.92002 12.1 4.52002 11.29L4.95002 10.71C6.50002 8.62 8.99002 7.41 11.55 7.41C12.04 7.41 12.53 7.45 13.02 7.54L38.67 12.28L43.48 5.75H44.19L41.6 15.42L41.61 15.43Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconAirplane extends Vue {}
</script>
