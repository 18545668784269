<template>
  <SvgBase iconName="Truck" :width="48" :height="30" fill="none">
    <svg
      width="48"
      height="30"
      viewBox="0 0 48 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3277 25.0321H0V15.3892L6.2521 6.33079H15.3277V10.2269H8.47059L4.03361 16.6555V21.136H15.3277V25.0321Z"
        :fill="color"
      />
      <path
        d="M47.9994 25.0324H13.0078V0H47.9994V25.0324ZM17.0414 21.1364H43.9657V3.8961H17.0414V21.1364Z"
        :fill="color"
      />
      <path
        d="M8.57202 29.0254C6.35353 29.0254 4.63924 27.2722 4.63924 25.2267C4.63924 23.1813 6.35353 21.428 8.57202 21.428C10.7905 21.428 12.5048 23.1813 12.5048 25.2267C12.5048 27.2722 10.7905 29.0254 8.57202 29.0254ZM8.57202 24.35C8.06781 24.35 7.66446 24.7396 7.66446 25.2267C7.66446 25.7137 8.06781 26.1033 8.57202 26.1033C9.07622 26.1033 9.47958 25.7137 9.47958 25.2267C9.47958 24.7396 9.07622 24.35 8.57202 24.35Z"
        :fill="color"
      />
      <path
        d="M36.9079 29.0254C34.6895 29.0254 32.9752 27.2722 32.9752 25.2267C32.9752 23.1813 34.7903 21.428 36.9079 21.428C39.0256 21.428 40.8408 23.1813 40.8408 25.2267C40.8408 27.2722 39.0256 29.0254 36.9079 29.0254ZM36.9079 24.35C36.4038 24.35 36.0004 24.7396 36.0004 25.2267C36.0004 25.7137 36.4038 26.1033 36.9079 26.1033C37.4122 26.1033 37.8155 25.7137 37.8155 25.2267C37.8155 24.7396 37.4122 24.35 36.9079 24.35Z"
        :fill="color"
      />
      <path d="M9.68087 14.0253H2.017V17.9213H9.68087V14.0253Z" :fill="color" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTruck extends Vue {}
</script>
