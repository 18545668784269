<template>
  <SvgBase iconName="GroupOfCode" width="40" height="40">
    <path d="M39.38 37.1H0V4H4V33.1H35.38V4H39.38V37.1Z" :fill="color" />
    <path d="M10.41 17.1602H6.40997V21.1602H10.41V17.1602Z" :fill="color" />
    <path d="M14.41 21.1602H10.41V25.1602H14.41V21.1602Z" :fill="color" />
    <path d="M18.41 17.1602H14.41V21.1602H18.41V17.1602Z" :fill="color" />
    <path d="M10.41 25.1602H6.40997V29.1602H10.41V25.1602Z" :fill="color" />
    <path d="M18.41 25.1602H14.41V29.1602H18.41V25.1602Z" :fill="color" />
    <path d="M24.69 17.1602H20.69V21.1602H24.69V17.1602Z" :fill="color" />
    <path d="M28.69 21.1602H24.69V25.1602H28.69V21.1602Z" :fill="color" />
    <path d="M32.69 17.1602H28.69V21.1602H32.69V17.1602Z" :fill="color" />
    <path d="M24.69 25.1602H20.69V29.1602H24.69V25.1602Z" :fill="color" />
    <path d="M32.69 25.1602H28.69V29.1602H32.69V25.1602Z" :fill="color" />
    <path d="M10.41 3H6.40997V7H10.41V3Z" :fill="color" />
    <path d="M14.41 7H10.41V11H14.41V7Z" :fill="color" />
    <path d="M18.41 3H14.41V7H18.41V3Z" :fill="color" />
    <path d="M10.41 11H6.40997V15H10.41V11Z" :fill="color" />
    <path d="M18.41 11H14.41V15H18.41V11Z" :fill="color" />
    <path d="M24.69 3H20.69V7H24.69V3Z" :fill="color" />
    <path d="M28.69 7H24.69V11H28.69V7Z" :fill="color" />
    <path d="M32.69 3H28.69V7H32.69V3Z" :fill="color" />
    <path d="M24.69 11H20.69V15H24.69V11Z" :fill="color" />
    <path d="M32.69 11H28.69V15H32.69V11Z" :fill="color" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconNode extends Vue {}
</script>
