<template>
  <SvgBase iconName="ForkLift" width="43" height="39" fill="none">
    <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12092_4103)">
        <path
          d="M42.32 30.4386H42.38V15.4986H39.16V0.0585938H23.11L12.04 17.8686V31.4386H12.14C12.14 31.4386 12.14 31.5086 12.14 31.5486C12.14 35.3286 15.22 38.4086 19 38.4086C22.13 38.4086 24.76 36.3086 25.59 33.4386H28.98C29.8 36.2986 32.44 38.4086 35.57 38.4086C39.35 38.4086 42.43 35.3286 42.43 31.5486C42.43 31.1686 42.39 30.7986 42.33 30.4386H42.32ZM25.33 4.05859H35.16V15.4986H18.22L25.33 4.05859ZM19 34.4086C17.42 34.4086 16.14 33.1286 16.14 31.5486C16.14 29.9686 17.42 28.6886 19 28.6886C20.58 28.6886 21.86 29.9686 21.86 31.5486C21.86 33.1286 20.58 34.4086 19 34.4086ZM29.04 29.4386H25.52C24.63 26.6886 22.04 24.6886 19 24.6886C17.94 24.6886 16.94 24.9386 16.04 25.3686V19.4986H38.38V25.2986C37.52 24.9086 36.57 24.6886 35.56 24.6886C32.52 24.6886 29.93 26.6886 29.04 29.4386ZM35.56 34.4086C33.98 34.4086 32.7 33.1286 32.7 31.5486C32.7 29.9686 33.98 28.6886 35.56 28.6886C37.14 28.6886 38.42 29.9686 38.42 31.5486C38.42 33.1286 37.14 34.4086 35.56 34.4086Z"
          :fill="color"
        />
        <path d="M6.33 32H0V36H10.33V0H6.33V32Z" :fill="color" />
      </g>
      <defs>
        <clipPath id="clip0_12092_4103">
          <rect width="42.42" height="38.41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconForkLift extends Vue {}
</script>
