<template>
  <SvgBase iconName="Paper" width="38" height="38" fill="none">
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.21 0H0V37.38H37.34V13.13L24.21 0ZM25.29 6.73L30.61 12.05L25.18 12.16L25.29 6.73ZM4 33.38V4H21.34L21.1 16.24L33.34 16V33.38H4Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPaper extends Vue {}
</script>
