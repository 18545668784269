<template>
  <SvgBase iconName="Packages" width="44" height="38" fill="none">
    <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.72 16.95V0H9.95V16.95H0V37.52H43.67V16.95H33.72ZM19.77 33.52H4V20.95H9.13V25.17H14.63V20.95H19.76V33.52H19.77ZM19.91 16.95H13.96V4H19.09V8.22H24.59V4H29.72V16.95H19.91ZM39.67 33.52H23.91V20.95H29.04V25.17H34.54V20.95H39.67V33.52Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPackages extends Vue {}
</script>
