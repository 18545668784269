<template>
  <SvgBase iconName="Warehouse" width="39" height="39" fill="none">
    <svg width="40" height="38" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.67 0L0 10.2V37.63H39.34V10.2L19.67 0ZM16.44 33.63V25.82H22.9V33.63H16.44ZM35.34 33.63H26.9V21.82H12.44V33.63H4V12.64L19.67 4.51L35.34 12.64V33.64V33.63Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconWarehouse extends Vue {}
</script>
