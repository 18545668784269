<template>
  <SvgBase iconName="Inventory" width="39" height="39" fill="none">
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.46 0V4.29H10.43V0H0V38.38H10.43V33.7H28.46V38.38H38.89V0H28.46ZM28.46 8.29V12.76H10.43V8.29H28.46ZM28.46 16.76V21.23H10.43V16.76H28.46ZM6.43 34.38H4V4H6.43V34.38ZM10.43 29.7V25.23H28.46V29.7H10.43ZM34.89 34.38H32.46V4H34.89V34.38Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconInventory extends Vue {}
</script>
