
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import IconContainer from '../svg/IconContainer.vue'
import IconLeaf from '../svg/IconLeaf.vue'
import IconNode from '../svg/IconNode.vue'
import IconOrphan from '../svg/IconOrphan.vue'
import IconTimeTable from '../svg/IconTimeTable.vue'
import IconTruck from '../svg/IconTruck.vue'
import IconVaccine from '../svg/IconVaccine.vue'

import IconAirplane from '../svg/barcodeIcon/IconAirplane.vue'
import IconForkLift from '../svg/barcodeIcon/IconForkLift.vue'
import IconInventory from '../svg/barcodeIcon/IconInventory.vue'
import IconPackage from '../svg/barcodeIcon/IconPackage.vue'
import IconPackages from '../svg/barcodeIcon/IconPackages.vue'
import IconPaper from '../svg/barcodeIcon/IconPaper.vue'
import IconPerson from '../svg/barcodeIcon/IconPerson.vue'
import IconRack from '../svg/barcodeIcon/IconRack.vue'
import IconShip from '../svg/barcodeIcon/IconShip.vue'
import IconTrain from '../svg/barcodeIcon/IconTrain.vue'
import IconWarehouse from '../svg/barcodeIcon/IconWarehouse.vue'
import { BARCODE_ICONS } from 'smartbarcode-web-core/src/utils/constants'
@Options({
  components: {
    name: 'BarcodeTypesMixin',
  },
})
export default class BarcodeTypesMixin extends Vue {
  barcodeIconComponents = {
    [BARCODE_ICONS.NODE]: IconNode,
    [BARCODE_ICONS.LEAF]: IconLeaf,
    [BARCODE_ICONS.ORPHAN]: IconOrphan,
    [BARCODE_ICONS.VACCINE]: IconVaccine,
    [BARCODE_ICONS.TRUCK]: IconTruck,
    [BARCODE_ICONS.CONTAINER]: IconContainer,
    [BARCODE_ICONS.TIMETABLE]: IconTimeTable,
    [BARCODE_ICONS.INVENTORY]: IconInventory,
    [BARCODE_ICONS.WAREHOUSE]: IconWarehouse,
    [BARCODE_ICONS.RACK]: IconRack,
    [BARCODE_ICONS.FORKLIFT]: IconForkLift,
    [BARCODE_ICONS.PACKAGES]: IconPackages,
    [BARCODE_ICONS.PACKAGE]: IconPackage,
    [BARCODE_ICONS.PAPER]: IconPaper,
    [BARCODE_ICONS.PERSON]: IconPerson,
    [BARCODE_ICONS.TRAIN]: IconTrain,
    [BARCODE_ICONS.SHIP]: IconShip,
    [BARCODE_ICONS.AIRPLANE]: IconAirplane,
  }

  getIconByKey(key: string): object {
    return this.barcodeIconComponents[key]
  }

  barcodeButtonStyles(barcode: IBarcodeDefinitionType) {
    return {
      ...this.barcodeIconStyles(barcode.invertIconColor),
      background: `${barcode.backgroundColor} !important`,
      'border-color': `${barcode.backgroundColor} !important`,
    }
  }

  barcodeIconStyles(isInvertColor: boolean) {
    return {
      color: `${!isInvertColor ? '#fff' : '#000'} !important`,
    }
  }

  barcodeButtonTextStyles = (barcodeTypeInfo: IBarcodeDefinitionType) => {
    return {
      color: `${barcodeTypeInfo.foregroundColor} !important`,
    }
  }
}
