<template>
  <SvgBase iconName="Train" width="41" height="44" fill="none">
    <svg width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7616 23.9883H10.4722V27.5628H14.7616V23.9883Z" :fill="color" />
      <path d="M25.664 3.92969H15.834V7.50423H25.664V3.92969Z" :fill="color" />
      <path d="M30.5492 23.9883H26.2598V27.5628H30.5492V23.9883Z" :fill="color" />
      <path
        d="M35.5429 43.8477H41L35.2688 33.5292C36.8774 32.6237 37.9736 30.9317 37.9736 28.9657V5.83842C37.9736 2.62133 35.3522 0 32.1351 0H8.84103C5.62395 0 3.00262 2.62133 3.00262 5.83842V28.9538C3.00262 31.2177 4.45626 33.136 6.45801 33.8747L4.5635 36.794H4.53967V36.8416L0 43.8477H5.68352L7.161 41.56H34.2679L35.5429 43.8477ZM8.25719 29.4423C7.98314 29.4423 7.76867 29.2278 7.76867 28.9538V22.1979H33.1956V28.9538C33.1956 29.2278 32.9811 29.4423 32.7071 29.4423H8.25719ZM8.84103 4.76606H32.1232C32.719 4.76606 33.1956 5.24266 33.1956 5.83842V17.4319H7.76867V5.83842C7.76867 5.24266 8.24528 4.76606 8.84103 4.76606ZM10.247 36.794L11.9151 34.2084H30.1811L31.6109 36.794H10.247Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTrain extends Vue {}
</script>
