<template>
  <SvgBase iconName="IconTimeTable" :width="28" :height="22" :fill="color">
    <g clip-path="url(#clip0)">
      <path
        d="M13.3151 17.4533L11.7173 15.4933L15.3695 10.92L11.7173 6.34666L13.3151 4.38666L18.6412 10.92L13.3151 17.4533Z"
        :fill="color"
      />
      <path d="M9.28261 21.84H0V0H9.28261V21.84ZM3.04348 18.1067H6.23913V3.73333H3.04348V18.1067Z" :fill="color" />
      <path
        d="M28.0001 21.84H18.6414V0H27.924V21.84H28.0001ZM21.6848 18.1067H24.8805V3.73333H21.6848V18.1067Z"
        :fill="color"
      />
      <path d="M15.5979 9.05334H7.53271V12.7867H15.5979V9.05334Z" :fill="color" />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTimeTable extends Vue {}
</script>
