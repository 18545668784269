
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeTypesMixin from './mixins/BarcodeTypesMixin.vue'

@Options({
  components: {
    name: 'BarcodeIcon',
  },
})
export default class BarcodeIcon extends mixins(BarcodeTypesMixin) {
  @Prop({ type: Object }) readonly barcodeType!: IBarcodeDefinitionType
  @Prop({ type: Boolean }) readonly isSelected?: boolean = false
  @Prop({ type: Boolean }) readonly isShowLabel?: boolean = false
  @Prop({ type: Boolean }) readonly isPointer?: boolean = true

  get textColorStyles() {
    return {
      color: this.isSelected ? '#fff' : '#000',
    }
  }
}
