<template>
  <SvgBase iconName="Ship" width="43" height="36" fill="none">
    <svg width="43" height="36" viewBox="0 0 43 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.48 14.96V3.67H31.25V0H27.25V3.67H16.25V14.97H0L4.99 29.36C6.23 32.94 9.61 35.34 13.39 35.34H39.92L42.41 14.97H37.49L37.48 14.96ZM20.25 7.66H33.48V14.96H31.14V11.61H27.81V14.96H25.81V11.61H22.48V14.96H20.25V7.66ZM36.38 31.33H13.39C11.31 31.33 9.45 30.01 8.77 28.04L5.62 18.96H37.89L36.38 31.33Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconShip extends Vue {}
</script>
