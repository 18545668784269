<template>
  <SvgBase iconName="Rack" width="48" height="40" fill="none">
    <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.8 13.85L24.93 0.3C24.28 -0.1 23.47 -0.1 22.82 0.3L0.95 13.85C0.36 14.21 0 14.86 0 15.55V24.7L23.87 39.49L47.74 24.7V15.55C47.74 14.86 47.38 14.21 46.79 13.85H46.8ZM37.98 22.71V26.04L29.65 31.2V27.87L37.98 22.71ZM23.87 4.35L41.95 15.55L23.87 26.75L5.8 15.55L23.87 4.35ZM18.09 27.87V31.2L9.76 26.04V22.71L18.09 27.87ZM3.99 19.14L5.75 20.23V23.56L3.99 22.47V19.14ZM22.09 33.68V30.35L22.81 30.8C23.13 31 23.5 31.1 23.86 31.1C24.22 31.1 24.59 31 24.91 30.8L25.63 30.35V33.68L23.85 34.78L22.07 33.68H22.09ZM41.98 23.56V20.23L43.74 19.14V22.47L41.98 23.56Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRack extends Vue {}
</script>
